
(function(factory) {
	'use strict';

	if( document.querySelector('input[type="file"]') ) {
		factory(window.Clique);
	}
})(function(_c) {
	'use strict';

	// vars
	var inputs = document.querySelectorAll('input[type="file"]');
	var $inputs = _c.$(inputs);

	// functions
	function multipleFiles($button, filelist) {
		$button.text( filelist.length + ' Files Selected' );
	}

	function singleFile($button, file) {
		$button.text( file.name );
	}

	function createFalseInput(i, input) {
		var $input = _c.$(input);
		var $button = _c.$('<button class="photo">\n' +
            '                        <svg width="26px" height="21px" viewBox="0 0 26 21">\n' +
            '                            <desc>Open Camera</desc>\n' +
            '                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
            '                                <g id="Art-Detail" transform="translate(-1254.000000, -720.000000)" fill-rule="nonzero"\n' +
            '                                   fill="#FFFFFF">\n' +
            '                                    <g id="Group-4" transform="translate(1146.000000, 707.000000)">\n' +
            '                                        <g id="noun_26455" transform="translate(108.000000, 13.200000)">\n' +
            '                                            <path d="M3.36976744,2.81641791 L8.35116279,2.81641791 L8.35116279,1.75074627 L3.36976744,1.75074627 L3.36976744,2.81641791 Z M9.81627907,2.81641791 L22.5627907,2.81641791 C23.9370698,2.81641791 25.0534884,3.97647761 25.0534884,5.40447761 L25.0534884,17.7358209 C25.0534884,19.1638209 23.9370698,20.3238806 22.5627907,20.3238806 L2.6372093,20.3238806 C1.26293023,20.3238806 0.146511628,19.1638209 0.146511628,17.7358209 L0.146511628,5.40447761 C0.146511628,4.24137313 0.884930233,3.25791045 1.90465116,2.92907463 L1.90465116,1.50716418 C1.90465116,0.800776119 2.49655814,0.228358209 3.22325581,0.228358209 L8.49767442,0.228358209 C9.22437209,0.228358209 9.81627907,0.800776119 9.81627907,1.50716418 L9.81627907,2.81641791 Z M23.5883721,17.7358209 L23.5883721,5.40447761 C23.5883721,4.81683582 23.1283256,4.33880597 22.5627907,4.33880597 L8.49767442,4.33880597 L3.22325581,4.33880597 L2.6372093,4.33880597 C2.07167442,4.33880597 1.61162791,4.81683582 1.61162791,5.40447761 L1.61162791,17.7358209 C1.61162791,18.3234627 2.07167442,18.8014925 2.6372093,18.8014925 L22.5627907,18.8014925 C23.1283256,18.8014925 23.5883721,18.3234627 23.5883721,17.7358209 Z"\n' +
            '                                                  id="Shape"></path>\n' +
            '                                            <path d="M12.7816744,5.40447761 C16.0957674,5.40447761 18.7886512,8.20567164 18.7886512,11.6462687 C18.7886512,15.0868657 16.0957674,17.8880597 12.7816744,17.8880597 C9.47051163,17.8880597 6.77469767,15.0868657 6.77469767,11.6462687 C6.77469767,8.20567164 9.47051163,5.40447761 12.7816744,5.40447761 Z M17.3235349,11.6462687 C17.3235349,9.04298507 15.2870233,6.92686567 12.7816744,6.92686567 C10.2792558,6.92686567 8.23981395,9.04298507 8.23981395,11.6462687 C8.23981395,14.2495522 10.2792558,16.3656716 12.7816744,16.3656716 C15.2870233,16.3656716 17.3235349,14.2495522 17.3235349,11.6462687 Z"\n' +
            '                                                  id="Shape"></path>\n' +
            '                                            <path d="M12.8930233,8.44925373 C14.6306512,8.44925373 16.0430233,9.91683582 16.0430233,11.7223881 C16.0430233,13.5279403 14.6306512,14.9955224 12.8930233,14.9955224 C11.1553953,14.9955224 9.74302326,13.5279403 9.74302326,11.7223881 C9.74302326,9.91683582 11.1553953,8.44925373 12.8930233,8.44925373 Z M14.577907,11.7223881 C14.577907,10.757194 13.821907,9.97164179 12.8930233,9.97164179 C11.9641395,9.97164179 11.2081395,10.757194 11.2081395,11.7223881 C11.2081395,12.6875821 11.9641395,13.4731343 12.8930233,13.4731343 C13.821907,13.4731343 14.577907,12.6875821 14.577907,11.7223881 Z"\n' +
            '                                                  id="Shape"></path>\n' +
            '                                        </g>\n' +
            '                                    </g>\n' +
            '                                </g>\n' +
            '                            </g>\n' +
            '                        </svg>\n' +
            '                    </button>');
		$input.before( $button );
		$input.on('change', function() {
			if( this.files.length > 1 ) {
				multipleFiles($button, this.files);
			} else {
				singleFile($button, this.files[0]);
			}
		});
	}

	function onRender() {
		$inputs.each(createFalseInput);
	}

	// execute
	_c.$doc.one('ready.file_upload', onRender);
	_c.$doc.on('gform_post_render', onRender);

});
